import React, { useState } from 'react';
import Img from 'gatsby-image';

import PropTypes from 'prop-types';
import { Container } from './gallery.css';
import { Link } from 'rebass';
import { Flex, Box } from 'reflexbox/styled-components';

import { Title } from '../gallery/item/item.css';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { chunk, sum } from '../../utils/array';

const Gallery = ({ items, itemsPerRow: itemsPerRowByBreakpoints = [1] }) => {
  const aspectRatios = items.map(
    item => item.image.childImageSharp.fluid.aspectRatio
  );

  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        sum(rowAspectRatios)
      )
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0);

  const closeModal = () => setModalIsOpen(false);
  const openModal = imageIndex => {
    setModalCurrentIndex(imageIndex);
    setModalIsOpen(true);
  };

  return (
    <>
      <Container className="gallery">
        {items.map((item, i) => (
          <Link
            key={i}
            alt={item.title}
            title={item.title}
            href={item.image.childImageSharp.fluid.originalImg}
            onClick={e => {
              e.preventDefault();
              openModal(i);
            }}
          >
            <Box
              width={rowAspectRatioSumsByBreakpoints.map(
                (rowAspectRatioSums, j) => {
                  const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j]);
                  const rowAspectRatioSum = rowAspectRatioSums[rowIndex];

                  return `${(item.image.childImageSharp.fluid.aspectRatio /
                    rowAspectRatioSum) *
                    100}%`;
                }
              )}
              css={`
                display: inline-block;
                vertical-align: middle;
                transition: filter 0.3s;
                border: 2px solid white;
                :hover {
                  filter: brightness(87.5%);
                }
              `}
            >
              <figure>
                <Img
                  fluid={item.image ? item.image.childImageSharp.fluid : {}}
                  title={item.title}
                  alt={item.title}
                />
                <figcaption>
                  <Title>{item.title}</Title>
                </figcaption>
              </figure>
            </Box>
          </Link>
        ))}

        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={closeModal}>
              <Carousel
                views={items.map(item => ({
                  source: item.image.childImageSharp.fluid.originalImg,
                  caption: item.title,
                }))}
                currentIndex={modalCurrentIndex}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Container>
    </>
  );
};

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  aspectRatio: PropTypes.number,
  itemsPerRow: PropTypes.arrayOf(PropTypes.number),
};

export default Gallery;
