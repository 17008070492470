import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  bottom: 0;

  ${MEDIA.mobileS`
  font-size: 1rem;
  `};

  ${MEDIA.mobileM`
  font-size: 1rem;
  `};

  ${MEDIA.mobileL`
  font-size: 1rem;
  `};

  ${MEDIA.tablet`
  font-size: 1.5rem;
  `};

  ${MEDIA.laptop`
  font-size: 1.5rem;
  `};

  ${MEDIA.laptopL`
  font-size: 1.5rem;
  `};
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;

  ${MEDIA.tablet`
    margin-bottom: 4rem;
  `};
`;
